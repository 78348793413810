import React from "react";
import { useGlobalContext } from "../context";
import family from "../images/Family.jpg";
import dice from "../images/dice.jpg";

const About = () => {
  const { closeSubmenu } = useGlobalContext();
  return (
    <section className="about" onMouseOver={closeSubmenu}>
      <div className="about-title">
        <h1>About Me</h1>
        <div className="about-underline"></div>
      </div>
      <div className="about-subtitle" id="family">
        <h2>My Family</h2>
      </div>
      <div className="about-info">
        <p>
          I live with my wife and son, Natasha and Samwise, in Sherman, TX. We also have 2 cats, Mipha and Rohan. Natasha is a full-time
          comic artist and author of the comic "Pot of Gold" on Webtoon. <br />
          <br />
          Together we love watching movies and shows as well as playing
          Dungeon's and Dragons. We also enjoy fencing and spending time with
          our friends.
          <br />
        </p>
        <img src={family} alt="" className="about-photo" />
      </div>
      <div className="underline"></div>
      <div className="about-subtitle" id="hobbies">
        <h2>My Hobbies</h2>
      </div>
      <div className="about-info">
        <img src={dice} alt="" className="about-photo" />
        <p>
          Being the nerd that I am, I enjoy quite a variety of activities. I
          love Dungeons and Dragons, Warhammer 40k, Magic: The Gathering, and a
          large variety of video games <br /> <br />
          On the more physical side of things, I also enjoy LARPing and fencing,
          as well as working out. <br />
          <br />I also love coding, both web and game development. In
          general, I love tinkering with and learning new things
        </p>
      </div>
    </section>
  );
};

export default About;
