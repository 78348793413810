import React from "react";
import { useGlobalContext } from "../context";

const Skills = () => {
  const { closeSubmenu } = useGlobalContext();
  return (
    <>
      <section className="about" onMouseOver={closeSubmenu} id="ui">
        <div className="about-title">
          <h1>Skills</h1>
          <div className="about-underline"></div>
        </div>
        <div className="skills-card">
          <h2 className="skills-title">UI/UX Design</h2>
          <p className="skills-p">
            I have over 3 years of experience designing and implementing visually pleasing, responsive, and functional web pages using HTML, CSS, JavaScript, and React.
          </p>
        </div>
      </section>
      <section className="about" onMouseOver={closeSubmenu} id="web">
        <div className="about-title">
          <div className="about-underline"></div>
        </div>
        <div className="skills-card">
          <h2 className="skills-title">Web Applications</h2>
          <p className="skills-p">
            Along with my front-end experience, I also have experience in connecting front-end to back-end software. I have developed pages and forms that interact with back-end databases, REST APIs, and cloud services, including experience with tools like SQL, MongoDB, and Google Analytics.
          </p>
        </div>
      </section>
      <section className="about" onMouseOver={closeSubmenu} id="software">
        <div className="about-title">
          <div className="about-underline"></div>
        </div>
        <div className="skills-card">
          <h2 className="skills-title">Software Development</h2>
          <p className="skills-p">
            Beyond common web design using JavaScript and associated frameworks, I also have experience with C++, C#, and Python managind data structures and algorithms of all sorts.
          </p>
        </div>
      </section>
      <section className="about" onMouseOver={closeSubmenu} id="game">
        <div className="about-title">
          <div className="about-underline"></div>
        </div>
        <div className="skills-card">
          <h2 className="skills-title">Game Development and Animation</h2>
          <p className="skills-p">
            I have dabbled in game development as a hobby for 6 years using game engines such as Unity, Unreal Engine, and Godot. In my pursuit of this hobby, I have also learned to use 3D design and animation software including Autodesk Maya, Blender, and Substance Painter. I also have experience animating 2D images using live2D
          </p>
        </div>
      </section>
    </>
  );
};

export default Skills;
