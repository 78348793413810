import {
  FaJedi,
  FaHubspot,
  FaReact,
  FaEdge,
  FaDiscord,
  FaUnity,
  FaWrench,
  FaFortAwesome,
  FaMailBulk,
  FaDragon,
} from "react-icons/fa";
import React from "react";
const sublinks = [
  {
    page: "home",
    links: [{ label: "home", icon: <FaFortAwesome />, url: "/" }],
  },
  {
    page: "skills",
    links: [
      { label: "UI/UX Design", icon: <FaEdge />, url: "/skills#ui" },
      {
        label: "Web Applications", icon: <FaDiscord />, url: "/skills#web",
      },
      { label: "Software Development", icon: <FaWrench />, url: "/skills#software" },
      { label: "Game Development and Animation", icon: <FaUnity />, url: "/skills#game" },
    ],
  },
  {
    page: "portfolio",
    links: [
      { label: "react", icon: <FaReact />, url: "/portfolio#react" },
      { label: "Live2D", icon: <FaDragon />, url: "/portfolio#live2d" },
    ],
  },
  {
    page: "about",
    links: [
      { label: "family", icon: <FaHubspot />, url: "/about#family" },
      { label: "hobbies", icon: <FaJedi />, url: "/about#hobbies" },
    ],
  },
  {
    page: "contact",
    links: [{ label: "contact", icon: <FaMailBulk />, url: "/contact" }],
  },
];

export default sublinks;
